import { Injectable, NgZone } from '@angular/core';
import {
	Auth,
	getRedirectResult,
	OAuthProvider,
	onAuthStateChanged,
	signInWithCredential,
	signInWithRedirect,
	signOut,
	useDeviceLanguage,
	User as FirebaseUser
} from '@angular/fire/auth';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { environment } from '@rle-environments/environment';
import { UserDefaultsService } from '@rle-features/user/user-defaults.service';
import { NotificationService } from '@rle-shared/notification.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { from, Observable, of, ReplaySubject, Subject, Subscription } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class AuthService {
	public static PASSWORD_STRENGTH_REGEX = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!"§$%&/()=?*+#<>@]).{8,}';
	public static PASSWORD_STRENGTH_SPECIAL_CHARS = '!"§$%&/()=?*+#<>@';

	private static REDIRECT_URL_STORAGE_KEY = 'login_redirect_url';

	public loggedInUser: Observable<FirebaseUser | null>;
	public loggedInUserId: Observable<string | null>;
	public tokenRefreshed: Observable<boolean>;

	private loggedInUserSubscription?: Subscription;
	private loggedInUserSubject = new ReplaySubject<FirebaseUser | null>(1);
	private loggedInUserIdSubject = new ReplaySubject<string | null>(1);
	private currentLoggedInUser: FirebaseUser | null = null;
	private authStateChangeInProgress = false;
	private tokenRefreshedSubject = new Subject<boolean>();
	private isEmbeddedInNativeValue: boolean = false;

	constructor(
		private auth: Auth,
		private permissionsService: NgxPermissionsService,
		private userDefaultsService: UserDefaultsService,
		private router: Router,
		private platform: Platform,
		private zone: NgZone,
		private notificationService: NotificationService
	) {
		this.loggedInUserIdSubject.next(null);
		this.loggedInUser = this.loggedInUserSubject.asObservable();
		this.loggedInUserId = this.loggedInUserIdSubject.asObservable();
		this.tokenRefreshed = this.tokenRefreshedSubject.asObservable();

		// Check if this app is embedded within a native iframe
		const params = new URLSearchParams(new URL(window.location.href).hash.substring(1));
		if (params.get('embeddedInNative')) {
			this.isEmbeddedInNativeValue = params.get('embeddedInNative') === 'true';
			this.userDefaultsService.setIsEmbeddedInNative(this.isEmbeddedInNativeValue);
		}
	}

	public init(): Promise<boolean> {
		return (
			this.platform
				.ready()
				.then(() => this.userDefaultsService.getIsEmbeddedInNative())
				.then(isEmbedded => {
					this.isEmbeddedInNativeValue = isEmbedded === true || this.isEmbeddedInNativeValue;
					return Promise.resolve();
				})
				.then(() => useDeviceLanguage(this.auth))
				.then(() => this.initAuthStateHandler())
				// Load current Firebase user to ensure that user is loaded before first login check
				.then(() => this.processLoginReturnResult())
				.catch(error => {
					if (error?.code === 'auth/user-cancelled') {
						return Promise.resolve(false);
					}

					this.processError(error, true);
					return signOut(this.auth).then(() => Promise.resolve(false));
				})
		);
	}

	public isEmbeddedInNative(): boolean {
		return this.isEmbeddedInNativeValue;
	}

	public isLoggedIn(): boolean {
		return !!this.currentLoggedInUser;
	}

	public getCurrentLoggedInUser(): FirebaseUser | null {
		return this.currentLoggedInUser ?? null;
	}

	public async login(origin?: string): Promise<void> {
		if (environment.clientLogEnabled) {
			console.log(`Show login with origin: ${origin}`);
		}
		this.setRedirectUrl(origin ?? null);

		if (this.isEmbeddedInNative()) {
			return new Promise<void>((resolve, reject) => {
				window.addEventListener(
					'message',
					event => {
						const data = JSON.parse(event.data ?? '');
						if (data.action === 'tokenExchangeResult') {
							console.log('Received tokens:', data);

							// Login to Firebase
							const provider = new OAuthProvider('oidc.keycloak');
							const credential = provider.credential({
								idToken: data.idToken,
								accessToken: data.accessToken,
								rawNonce: data.rawNonce
							});
							signInWithCredential(this.auth, credential)
								.then(userCredential => this.processAuthToken(userCredential.user, true))
								.then(() => resolve())
								.catch(err => reject(err));
						}
					},
					{ capture: true, once: true }
				);

				const data = JSON.stringify({ action: 'tokenExchange', clientId: 'rleportal-customers' });
				window.parent.postMessage(data, '*');
			});
		}
		const provider = new OAuthProvider('oidc.keycloak');
		provider.setCustomParameters({
			// Target specific email with login hint.
			//login_hint: 'user@example.com'
			//redirect_uri: redirectUri
		});
		provider.addScope('organisation');
		return signInWithRedirect(this.auth, provider).catch(error => this.processError(error));
	}

	public async logout(): Promise<boolean> {
		try {
			// Firebase logout
			await signOut(this.auth);
			//window.location.reload(); //.href = '/login/logout';
			return true;
		} catch (err) {
			await this.processError(err);
			return false;
		}
	}

	private initAuthStateHandler(): Promise<boolean> {
		return new Promise<boolean>((resolve, reject) => {
			onAuthStateChanged(
				this.auth,
				firebaseUser => {
					this.zone.run(async () => {
						await this.notificationService.showSpinner();
						this.authStateChangeInProgress = true;
						if (environment.clientLogEnabled) {
							console.log('authState changed: ', firebaseUser?.uid);
						}
						if (this.loggedInUserSubscription) {
							this.loggedInUserSubscription.unsubscribe();
						}
						this.loggedInUserSubscription = this.processUser(firebaseUser).subscribe({
							next: firebaseUser => {
								this.zone.run(async () => {
									await this.notificationService.hideSpinner();
									this.setCurrentUser(firebaseUser);

									if (this.authStateChangeInProgress) {
										this.redirectAfterLogin();
									}
									this.authStateChangeInProgress = false;
									resolve(true);
								});
							},
							error: error => {
								this.zone.run(async () => {
									// Ignore error if processing was canceled
									if (error) {
										this.processError(error, true);
									} else {
										await this.notificationService.hideSpinner();
									}
									this.authStateChangeInProgress = false;
									resolve(false);
								});
							}
						});
					});
				},
				async error => {
					this.authStateChangeInProgress = false;
					await this.notificationService.hideSpinner();
					reject(error);
				}
			);
		});
	}

	private processLoginReturnResult(): Promise<boolean> {
		if (this.isEmbeddedInNative()) {
			return Promise.resolve(false);
		}
		// Check result after redirect back from Identity Provider on web
		return getRedirectResult(this.auth).then(userCredential => Promise.resolve(!!userCredential));
	}

	private processUser(firebaseUser: FirebaseUser | null): Observable<FirebaseUser | null> {
		if (firebaseUser) {
			return this.auth.currentUser ? from(this.processAuthToken(firebaseUser)) : of(null);
		} else {
			this.permissionsService.flushPermissions();
			this.setRedirectUrl(null);
			return of(null);
		}
	}

	private processAuthToken(firebaseUser: FirebaseUser, forceRefresh = false): Promise<FirebaseUser> {
		if (!firebaseUser) {
			return Promise.resolve(firebaseUser);
		}

		return firebaseUser.getIdTokenResult(forceRefresh).then(tokenResult => {
			let permissionsList: string[] = (tokenResult?.claims?.['permissions'] as string[]) ?? [];
			this.permissionsService.loadPermissions(permissionsList);

			if (environment.clientLogEnabled) {
				console.log('Force token refresh: ', forceRefresh);
				console.log('Token claims: ', tokenResult.claims);
				console.log('User permissions: ', permissionsList);
			}

			this.tokenRefreshedSubject.next(true);
			return Promise.resolve(firebaseUser);
		});
	}

	private setCurrentUser(firebaseUser: FirebaseUser | null): void {
		const oldUserId = this.currentLoggedInUser?.uid ?? null;

		this.currentLoggedInUser = firebaseUser;
		this.loggedInUserSubject.next(firebaseUser ?? null);
		if (oldUserId !== (firebaseUser?.uid ?? null)) {
			this.loggedInUserIdSubject.next(firebaseUser?.uid ?? null);
		}
	}

	private setRedirectUrl(url: string | null): void {
		if (url && /^\/?(login|logout|error)(\/.*)?$/i.test(url)) {
			return;
		}
		if (url) {
			localStorage.setItem(AuthService.REDIRECT_URL_STORAGE_KEY, url);
		} else {
			localStorage.removeItem(AuthService.REDIRECT_URL_STORAGE_KEY);
		}
	}

	private getRedirectUrl(): string | null {
		return localStorage.getItem(AuthService.REDIRECT_URL_STORAGE_KEY);
	}

	private redirectAfterLogin(): void {
		const redirectUrl = this.getRedirectUrl();
		if (redirectUrl) {
			this.router.navigateByUrl((redirectUrl.startsWith('/') ? '' : '/') + redirectUrl, { replaceUrl: true });
			this.setRedirectUrl(null);
		}
	}

	private async processError(error: any, navigateToErrorPage = false): Promise<void> {
		if (environment.clientLogEnabled) {
			console.error(error);
		}
		await this.notificationService.hideSpinner();
		return navigateToErrorPage
			? this.router.navigateByUrl('/error').then(() => Promise.resolve())
			: this.notificationService.showMessage(this.getErrorMessage(error));
	}

	private getErrorMessage(error: any): string {
		const code =
			error && Object.prototype.hasOwnProperty.call(error, 'code')
				? error.code
				: error && Object.prototype.hasOwnProperty.call(error, 'message')
					? error.message
					: error && typeof error === 'string'
						? error
						: '';
		switch (code) {
			case 'auth/user-not-found':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/wrong-password':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/email-already-in-use':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/credential-already-in-use':
				return 'auth.messages.accountAlreadyExistOrLinkedToAnotherAcc';
			case 'auth/account-exists-with-different-credential':
				return 'auth.messages.emailAlreadyInUse';
			case 'auth/app-deleted':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/app-not-authorized':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-email':
				return 'auth.messages.enteredEmailIsInvalid';
			case 'auth/argument-error':
				return 'auth.messages.emailOrPasswordIsIncorrect';
			case 'auth/invalid-api-key':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-user-token':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/invalid-tenant-id':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/network-request-failed':
				return 'auth.messages.networkError';
			case 'auth/operation-not-allowed':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/operation-not-supported-in-this-environment':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/requires-recent-login':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/too-many-requests':
				return 'auth.messages.tooManyAttempts';
			case 'auth/unauthorized-domain':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/user-disabled':
				return 'auth.messages.accountDisabled';
			case 'auth/user-token-expired':
				return 'auth.messages.errorPleaseTryAgain';
			case 'auth/web-storage-unsupported':
				return 'auth.messages.errorPleaseTryAgain';
			default:
				return 'auth.messages.errorPleaseReloadBrowser';
		}
	}
}
