<ion-header>
	<ion-toolbar>
		<ion-title translate>Login</ion-title>
	</ion-toolbar>
</ion-header>

<ion-content>
	<ion-button *ngIf="!isLoggedIn()" (click)="login()">Login</ion-button>
	<ion-button *ngIf="isLoggedIn()" (click)="logout()">Logout</ion-button>
	<ion-button [routerLink]="['/']">Home</ion-button>
	<p>location.href={{href()}}</p>
	<p>Is native={{iframeNative()}}</p>
</ion-content>
