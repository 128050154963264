import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';

@Injectable({
	providedIn: 'root'
})
export class UserDefaultsService {
	private static EMBEDDED_IN_NATIVE = 'embeddedInNative';
	private static LAST_HANDLED_ERROR_TIMESTAMP = 'lastHandledErrorTimestamp';
	private static HANDLED_ERROR_RETRIES_COUNTER = 'handledErrorRetriesCounter';

	constructor() {}

	public getIsEmbeddedInNative(): Promise<boolean> {
		return this.getItem(UserDefaultsService.EMBEDDED_IN_NATIVE).then(v => v === 'true');
	}

	public setIsEmbeddedInNative(isEmbedded: boolean | null): Promise<void> {
		if (isEmbedded !== null) {
			return this.setItem(UserDefaultsService.EMBEDDED_IN_NATIVE, isEmbedded ? 'true' : 'false');
		} else {
			return this.removeItem(UserDefaultsService.EMBEDDED_IN_NATIVE);
		}
	}

	public getLastHandledErrorTimestamp(): Promise<number | null> {
		return this.getItem(UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP).then(v => (v === null ? null : +v));
	}

	public setLastHandledErrorTimestamp(timestamp: number | null): Promise<void> {
		if (timestamp) {
			return this.setItem(UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP, `${timestamp}`);
		} else {
			return this.removeItem(UserDefaultsService.LAST_HANDLED_ERROR_TIMESTAMP);
		}
	}

	public getHandledErrorRetriesCounter(): Promise<number> {
		return this.getItem(UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER).then(v => (v === null ? 0 : +v));
	}

	public setHandledErrorRetriesCounter(counter: number | null): Promise<void> {
		if (counter) {
			return this.setItem(UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER, `${counter}`);
		} else {
			return this.removeItem(UserDefaultsService.HANDLED_ERROR_RETRIES_COUNTER);
		}
	}

	private setObject(key: string, value: any): Promise<void> {
		return Preferences.set({
			key,
			value: JSON.stringify(value)
		});
	}

	private getObject(key: string): Promise<any> {
		return Preferences.get({ key }).then(ret => (ret && ret.value ? JSON.parse(ret.value) : null));
	}

	private setItem(key: string, value: string): Promise<void> {
		return Preferences.set({
			key,
			value
		});
	}

	private getItem(key: string): Promise<string | null> {
		return Preferences.get({ key }).then(ret => (ret ? ret.value : null));
	}

	private removeItem(key: string): Promise<void> {
		return Preferences.remove({ key });
	}
}
