import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { AuthService } from '@rle-core/auth/auth.service';

@Component({
	standalone: true,
	selector: 'app-login-page',
	templateUrl: './login.page.html',
	styleUrls: ['./login.page.scss'],
	imports: [CommonModule, RouterModule, IonicModule]
})
export class LoginPage {
	constructor(private authService: AuthService) {}

	public isLoggedIn(): boolean {
		return this.authService?.isLoggedIn() ?? false;
	}

	public login(): void {
		this.authService.login();
	}

	public logout(): void {
		this.authService.logout();
	}

	public href(): string {
		return window.location.href;
	}

	public iframeNative(): boolean {
		return this.authService.isEmbeddedInNative();
	}
}
