import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@rle-core/guards/auth.guard';
import { LoginPage } from '@rle-pages/public/login/login.page';

const routes: Routes = [
	{
		path: '',
		pathMatch: 'full',
		redirectTo: 'customers'
	},
	{
		path: 'customers',
		canLoad: [AuthGuard],
		loadChildren: () => import('@rle-features/customer/customer.module').then(m => m.CustomerModule)
	},
	{
		path: 'login',
		component: LoginPage
	},
	{
		path: 'error',
		loadChildren: () => import('@rle-pages/public/error/error.page.module').then(m => m.ErrorPageModule)
	},
	{
		path: '**',
		redirectTo: '/error/notfound'
	}
];
@NgModule({
	imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), LoginPage],
	exports: [RouterModule]
})
export class AppRoutingModule {}
